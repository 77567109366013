import React, { useState, useEffect } from "react";
import { getProducts, delProducts } from "../../../services/Admin.service";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import messageBox from "../../../utils/messagebox";
import ModalCreateProduct from "./ModalCreateProduct";
import { TableColumn } from "./Columna.js";  // Importa las columnas

function Products() {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [search, setSearch] = useState("");
  const [dataProductsUpdate, setDataProductsUpdate] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      getProducts()
        .then(({ data }) => {
          setProducts(data);
          setLoading(false);
        })
        .catch((err) => messageBox.Simple(err, "error"));
    }
  }, [loading]);

  function editProduct(id) {
    setDataProductsUpdate(products[id]);
    setActiveModal(true);
  }

  function deleteProduct(e, id) {
    e.preventDefault();
    delProducts(id)
      .then(({ data }) => {
        messageBox.Simple(data, "success");
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((err) => messageBox.Simple(err, "error"));
  }

  function redirectConcept(id) {
    navigate("/concepts", { state: { id } });
  }

  function redirectScope(id) {
    navigate("/scopes", { state: { id } });
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="ml-8 mt-10">
          <h1 className="mb-2 font-weight-bold" style={{ fontFamily: "Poppins", fontSize: "40px", color: "rgb(0, 71, 80)", fontWeight: "700" }}>
            Panel del auditor crear productos
          </h1>
          <div className="flex flex-col mx-2">
            <div className="overflow-x-auto">
              <div className="py-3 pl-2">
                <div className="lg:flex justify-between">
                  <div className="lg:w-1/2">
                    {/* Sección de búsqueda (comentada en tu código original) */}
                  </div>
                  <div className="lg:mb-5">
                    <button
                      type="button"
                      className="btn rounded-pill ColorButtonMainInv lg:text-sm xl:text-md lg:ml-2 flex"
                      onClick={() => setActiveModal(true)}
                    >
                      Crear nuevo producto
                    </button>
                  </div>
                </div>
              </div>
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-auto border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 table-auto" style={{ tableLayout: "fixed" }}>
                    <thead className="bg-gray-50">
                      <tr>
                        <TableColumn className="max-w-[150px]">Referencia</TableColumn>
                        <TableColumn className="max-w-[200px]">Título</TableColumn>
                        <TableColumn className="max-w-[100px]">Precio</TableColumn>
                        <TableColumn className="max-w-[100px] text-center">Importe</TableColumn>
                        <TableColumn className="max-w-[100px] text-center">Duración</TableColumn>
                        <TableColumn></TableColumn>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {products.length === 0 ? (
                        <tr>
                          <td className="text-center p-3 fs-5" colSpan="6">No hay productos registrados</td>
                        </tr>
                      ) : (
                        (search.length > 0 ? products.filter((product) =>
                          product.ref.toString().toLowerCase().includes(search.toLowerCase())
                        ) : products).map((product, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{product.ref}</td>
                            <td className="px-6 py-4 text-sm text-gray-800">{product.title}</td>
                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{product.price}</td>
                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{product.amount}</td>
                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{product.duration}</td>
                            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                              <button className="text-green-500 hover:text-green-700 mx-2" onClick={() => editProduct(index)}>Modificar</button>
                              <button className="text-yellow-500 hover:text-yellow-700 mx-2" onClick={(e) => deleteProduct(e, product.id)}>Eliminar</button>
                              <button className="text-blue-400 hover:text-blue-700 mx-2" onClick={() => redirectConcept(product.id)}>Conceptos</button>
                              <button className="text-blue-400 hover:text-blue-700 mx-2" onClick={() => redirectScope(product.id)}>Entregables</button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {activeModal && (
            <div className="App h-screen flex flex-col items-center justify-center bg-purple-200 vw-100 vh-100" style={{ zIndex: "10", background: "rgba(0, 162, 151, .55)", position: "fixed", top: "0", right: "0", bottom: "0", left: "0", overflowX: "hidden", overflowY: "auto" }}>
              <ModalCreateProduct dataProductsUpdate={dataProductsUpdate} setActiveModal={setActiveModal} setDataProductsUpdate={setDataProductsUpdate} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Products;
